html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
body {
    margin: 0;
    font-family: 'Montserrat'!important;
    display: flex;
    flex-direction: column;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.app {
    font-family: 'Montserrat'!important;
    font-style: normal;
}

.text-center{
    text-align: center !important;
}

/* HEADER CSS */
header{
    background: rgb(131, 186, 21);
    background: linear-gradient(90deg, rgba(131, 186, 21, 1) 0%, rgba(177, 216, 41, 1) 100%, rgba(0, 212, 255, 1) 100%);
    display: flex;
    padding: 20px 40px;
    height: 30px;
}
.header-back-link{
    color: #fff;
    display: flex;
    gap: 5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
.header-back-link span{
    color: #fff;
    font-size: 18px;
}
.header-back-link .icon{
    color: #fff;
    font-size: 20px;
}
.header-back-link .icon svg{
    color: #fff;
    font-size: 25px;
}
.logout-link{
    font-size: 25px;
}
.header-div{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.header-div-stil{
    justify-content: end;
}
.login-logo{
    margin: 0 auto;
     display:flex
}
.login-title{
    color: #84ba15;
    font-size: 15px;
    text-align: center;
}
@media only screen and (max-width : 450px) {
    .header-div{
        flex-direction: column;
    }
    .logout-link{
        text-align: right;
    }
    header{
        padding: 10px 20px;
    }
    .header-back-link span {
        color: #fff;
        font-size: 16px;
    }
}
  /* Main */
a{
    text-decoration: none;
}
  main{
    /* display: flex; */
    flex: 1;
    max-width: 100%;
    margin: 0 auto ;
    width: 100%;
  }
.content-main{
    display: flex;
    flex-direction: column;
    padding: 40px;
}
footer{
    padding: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}
.help-div{
    border: 1px solid #499781;
    border-radius: 10px;
    margin: 0 auto;
    /* max-width: 340px; */
    min-width: 340px;
    text-align: center;
    justify-content: center;
    align-content: center;
    color: #499781;
    font-size: 16px;
    font-weight: 600;
    height: auto;
    padding: 15px 0;
    width: 60%;
}
.amount-received{
    border: 1px solid #0d0f7b;
    border-radius: 6px;
    margin: 0 auto;
    min-width: 340px;
    text-align: center;
    justify-content: center;
    align-content: center;
    color: #0d0f7b;
    font-size: 16px;
    font-weight: 600;
    height: auto;
    padding:0;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 60%;
}
.amount-received-link{
    background-color:#0d0f7b ;
    color: #fff;
    padding: 16px;
    text-align: center;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    border: none;
}
.amount-received-txt{
    display: flex;
    flex-direction: column;
    padding: 16px;
    text-align: left;
}
.amount-received-t1{
    color: #0d0f7b;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 5px;
}
.amount-received-t2{
    color: #1f1f20;
    font-weight: 300;
    font-size: 17px;
    margin-bottom: 5px;
}
.invoices-application-box{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.invoices-application-txt{
    display: flex;
    flex-direction: column;
}
.invoices-application-icon{
    padding-top: 5px;
}

.tab-model .ant-tabs-tab{
    min-width: 200px;
    display: block;
    border: 1px solid #e6f1ff;
    border-radius: 8px;
    text-align: center;
    color:#1c1e83
}


.tab-model .ant-tabs .ant-tabs-ink-bar {
    position: relative;
    background:none;
    pointer-events: none;
    display: none;
}
.tab-model  .ant-tabs-tab-active{
    background: #fafbff;
    color:#1c1e83
}
.tab-model .ant-tabs-tab-active .ant-tabs-tab-btn{
    color:#1c1e83 !important
}
.tab-model .ant-tabs-nav-list{
    gap: 10px;
    width: 100%;
}
.ant-tabs-top >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-1adbn6x).ant-tabs-bottom >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-1adbn6x).ant-tabs-top >div>.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-1adbn6x).ant-tabs-bottom >div>.ant-tabs-nav::before {
    position: relative;
    right: 0;
    left: 0;
    border: none;
    content: '';
    display: none;
}

.ant-tabs-top >.ant-tabs-nav .ant-tabs-nav-wrap::after, :where(.css-dev-only-do-not-override-1adbn6x).ant-tabs-bottom >.ant-tabs-nav .ant-tabs-nav-wrap::after, :where(.css-dev-only-do-not-override-1adbn6x).ant-tabs-top >div>.ant-tabs-nav .ant-tabs-nav-wrap::after, :where(.css-dev-only-do-not-override-1adbn6x).ant-tabs-bottom >div>.ant-tabs-nav .ant-tabs-nav-wrap::after {
    right: auto;
    box-shadow:none;
    display: none;
}

.supplier-title{
    display:flex;
    width:100%;
    justify-content:space-around
}
@media only screen and (max-width : 780px) {
    .content-main{
        padding: 10px;
    }
}

@media only screen and (max-width : 450px) {
    .tab-model .ant-tabs-tab{
        min-width: 185px;
    }
    .help-div{
        max-width: auto;
        min-width: auto;
        width: 100%;
    }
    .amount-received{
        width: 100%;
        max-width: auto;
        min-width: auto;
    }
    .contract-modal-style .ant-tabs-nav-list{
        flex-direction: column;
    }
    .ant-tabs-ink-bar-animated{
        display: none;
    }
    .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
        margin-bottom: 3px;
    }
    .ant-tabs .ant-tabs-tab{
        padding: 4px 30px;
        background-color: #edebeb;
        margin-bottom: 3px;
    }
    .supplier-title{
        justify-content:space-around;
        flex-direction: column;
    }
}

@media only screen and (max-width : 420px) {
    .tab-model .ant-tabs-tab{
        min-width: 185px;
    }
}
@media only screen and (max-width : 390px) {
    .tab-model .ant-tabs-tab{
        min-width: 165px;
    }
}

.notes-div{
    padding: 20px;
    border: 1px solid #e6f1ff;
    border-radius: 8px;
    background-color: #fafbff;
    margin-bottom: 20px;
}
.notes-div ul{
    padding: 0;
    margin: 0;
}
.notes-div li{
    list-style: none;
}
.notes-div li span{
    color:#1c1e83;
    font-weight: 500;
}

 .ant-table-thead th{
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: start;
    background: #fafbff !important;
    border-bottom: 1px solid #e6f1ff !important;
}
.login-div{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center 
}
.ant-btn-primary{
    background-color: #84ba15;
}
.ant-input{
    padding: 10px 11px !important;
}
.ant-input-affix-wrapper{
    padding: 0px 11px 0px 0 !important;
}
.ant-btn-primary:hover {
    color: #fff;
    background-color:#afd629 !important;
}
.ant-btn {
    font-size: 15px;
    height: 38px;
    padding: 4px 15px;
    border-radius: 6px;
    letter-spacing: 1px;
    font-weight: 500;
}
/* .contract-modal button span {
    -webkit-text-decoration: underline;
    text-decoration: underline;
} */
.contract-modal .modal-lh {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding: 0 4px;
    border: none;
    height: auto !important;
    color: #84ba15 !important;
}
.ant-checkbox-disabled {
    cursor: not-allowed;
}
.contract-modal .ant-btn > span {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    color:#84ba15;
}
.text-box{
    padding:30px 0;
   font-size:12px;
   height: 500px;
   padding-right:20px;
   overflow-y: scroll;
}
.text-box .title-center{
    text-align:center
 }
 .text-box ul{
    margin-top:5px;
    margin-bottom:15px
 }
 .text-box ol{
    margin-left:0;
    padding-left: 20px;
 }
 .text-box table td{
    border:1px solid;
    padding:4px
 }
 .text-box .top-li {
    margin-top:15px
  }
  .text-box li::marker{
    font-weight:600;
  }
  .text-box   span{
    font-weight:600;
    margin-bottom:10px

  }

  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
}

.contract-modal-style .ant-modal-body {
    padding-right: 5px !important;
}
.ant-tabs-top, .ant-tabs-bottom {
    flex-direction: column;
}
.contract-modal-style .ant-tabs-nav {
    margin: 0 auto;
}
.contract-modal-style {
    width: 800px !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #84ba15 !important;
    text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #84ba15;
    pointer-events: none;
}
.ant-tabs .ant-tabs-tab-btn:active {
    color: #84ba15 !important;
}
.ant-tabs .ant-tabs-tab-btn:hover {
    color: #84ba15 !important;
}
.ant-tabs .ant-tabs-tab:hover {
    color: #84ba15;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #84ba15;
    border-color: #84ba15;
}
.ant-checkbox-inner {
    border-color: #84ba15;
}
.ant-checkbox-checked:hover {
    background-color: #84ba15 !important;
    border-color: transparent;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #84ba15 !important;
    border-color: transparent;}

.login-loading{
    height: 100%;
    display: grid;
}
.login-loading .ant-spin .ant-spin-text {
    font-size: 18px;
}
.login-loading .ant-spin-nested-loading >div>.ant-spin {
    position: static;
}

.ant-table-wrapper .ant-table-tbody >tr >td{
    font-size: 12px !important;
    padding: 10px 4px;
}
.ant-table-thead th{
font-size: 12px;
padding: 20px 4px !important;
}
.ant-table-wrapper .ant-table-tbody >tr >td:nth-child(1) {
    font-size: 12px !important;
    padding: 10px 0px !important;
}
.ant-result .ant-result-title {
    color: rgba(0, 0, 0, 0.88);
    font-size: 17px;
    line-height: 1.3333333333333333;
    margin-block: 8px;
    text-align: center;
    font-weight: bold;
}
.ant-result .ant-result-subtitle {
    color: rgb(0 0 0);
    font-size: 14px;
    line-height: 1.5714285714285714;
    text-align: center;

}
.invoiceTable .ant-table-measure-row{
    display: none;
}
.invoiceTable .ant-table-wrapper .ant-table-thead >tr>td{
    text-align: center;
}
.application-detail-table{
    padding: 15px;
    border-radius: 6px;
    border:1px solid #e6f1ff;
    background: #fafbff;
    margin-top: 20px;
}
.application-detail-table ul{
   margin: 0;
   padding: 0;
   list-style: none;
   font-size: 13px;
}
.application-detail-table  li{
   margin-bottom: 5px;
   margin-right: 5px;
   font-weight: 500;
 }
 .application-detail-table li span{
    color: #1c1e83;
    float: left;
    padding-right: 5px;
  }
  .routing-space{
    margin-bottom: 20px; 
    margin-top: 10px;
  }
  .routing-space button{
    background-color: #83ba15 !important; 
    border-color: #83ba15 !important; 
  }
  .second-btn{
    background-color: #499781;
    color:#fff;
    margin-top: 30px;
    height: 50px;
  }
  .second-btn:hover {
    color: #71d1b6 !important;
    border-color: #499781 !important;
}
.app-btn{
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 10px;
}


/* teklifler */

.offers-list{
    display: flex;
    flex-direction: column;
    max-width: 550px;
}

.offers-list .ant-btn-default:not(:disabled):not(.offers-list .ant-btn-disabled):hover {
    color: #fff;
    border-color: #afd629;
    background-color: #afd629 !important;
}


.table-row{
    display: flex;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    padding: 10px 0;
    font-size: 13px;
    margin-bottom: 15px;
}
.table-col{
    display: flex;
    flex-direction: column;
    padding-right: 20px;
}
.table-col .title{
   font-weight: 600;
   margin-bottom: 4px;
}
.table-row .table-div-first .table-col{
    border-right:1px solid #0d0f7b; 
    
}
.table-row .table-div-first .table-col:nth-child(3){
    border-right:none; 
    min-width: 130px;
    max-width: 130px;
}
.table-row .table-div-first .table-div .table-col:nth-child(3){
    border-right:none; 
    min-width: 140px;
    max-width: 140px;
}
.table-row .table-div-first .table-col:nth-child(1){
    min-width: 130px;
    max-width: 130px;
}
.table-row .table-div-first .table-col:nth-child(2){
    min-width: 145px;
    max-width: 145px;
}
.table-row .table-div-first .table-col:first-child{
    border-right:none;    
}
.table-row .table-div-first .table-col:nth-last-child(1){
    border-right:none;    
}

.last-limit b{
    color:#e34230
}
.last-limit h4{
    color:#e34230;
    white-space: nowrap
}
.table-div{
    background-color:#fbfbfb;
    display: flex;
    padding: 15px;
    border-radius:8px;
    border:1px solid #efefef;
    margin-right:0;
    padding-right: 0;
    margin-left: 10px;
}

.btn-color{
    background-color:#84ba15;
    color:#fff;
    border-color:#84ba15;
}

@media only screen and (max-width: 780px){
    .table-div{
        display: block;
    }
    .table-div div{
        width:100%;
        border-right: none !important;
        border-bottom: 1px solid rgb(237, 237, 237);
        padding-bottom: 4px;
        padding-top: 8px;
        padding-left: 0 !important;
        min-width: 160px !important;
    }
   
}  

.table-div-first{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  
}
.table-div .table-col {
    border-right: 1px solid rgb(237, 237, 237) !important;
    padding-left: 15px;
}
.table-div .table-col:nth-child(2) {
    border-right:none !important;
}
.table-div .table-col:last-child {
    border-right:none !important;
}
@media only screen and (max-width: 1680px) {
    .table-row{
        justify-content:space-between;
        gap: 0;
    } 
  }
@media only screen and (max-width: 780px){
    .table-row{
        display:block;
    }
    
    .table-div-first{
        margin-bottom:15px;
    }
    .table-div{
        display:flex
    }
    .table-div div{
       border:none
    }
}
@media only screen and (max-width: 960px){
   .table-row{
    display:block;
    padding:10px;
    width: auto;
   }
    .table-div-first{
        display:block;
        margin-bottom:0;
    }
    .table-div-first .table-col{
        border-right:none !important;
        border-bottom:1px solid #e7e7e8;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .table-div-first .table-col:last-child{
        border-right:none !important;
        border-bottom:none
    }
    .table-div-first .table-col:nth-child(1){
        border-right:none !important;
        border-bottom:none;
       
    }
    .table-div-first .table-col:nth-child(3){
        border-right:none !important;
        border-bottom:none;
        padding-left: 16px;
        padding-bottom: 0;
    }
    .table-div-first .table-col:nth-child(4){
        border-right:none !important;
        border-bottom:none
    }
    .table-div-first .table-col:nth-child(2){
        border-right:none !important;
        border-bottom:none;
        padding-left: 16px;
    }
    .table-div-first .table-div div:nth-child(2){
        border-right:none !important;
        border-bottom:none
    }
    .table-col:last-child{
        padding-right: 0;
    }
   .table-col:first-child{
    padding-bottom: 0;
   }
    .table-div{
        display:block;
        padding-top: 6px;
        margin-top: 10px;
    }
    .table-div div{
       border:none;
    }

 }